import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

//API
import { gql } from "apollo-boost";
import {
    setAgregatedPermissions,
    setPermissions,
    setAvailablePaths,
    setProjectLangs,
    showGlobalLoading,
} from "../../actions/uiActions";
import { routes } from "../../routes";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { Session } from "./Session";

//Utils
import ComposePermissionsTree from "./ComposePermissionsTree";
import { cloneObject, parseBoolean, pathsWithoutChainOrProjectRef } from "./Utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useIsDhcp } from "components/Section/Monitor/hooks/useIsDhcp";

const Permissions = ({ token }) => {
    //Consts&states
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const [newProjectSelected, setNewProjectSelected] = useState(null);
    const setPolicyToAccepted = Session.getSessionProp("setPolicyToAccepted");
    const userManagerRole = parseBoolean(Session.getSessionProp("userManagerRole"));
    const isSuperUser = Session.getSessionProp("superUser");
    const isCorporate = Session.getSessionProp("projectRef") === "CORPORATE";
    const { isDhcp, ready: DhcpReady, executeGetDhcpQuery } = useIsDhcp();
    const hasCorporateUser = parseBoolean(Session.getSessionProp("hasCorporateUser"));
    const userID = Session.getSessionProp("userID") || 0;
    const currentHotel = Session.getSessionProp("currentHotel")
        ? JSON.parse(Session.getSessionProp("currentHotel"))
        : null;
    const HOTEL_ID = currentHotel?.id ? currentHotel.id : "";
    const projects = JSON.parse(Session.getSessionProp("projects"));
    const projectData = projects ? projects.filter((project) => parseInt(project.id) === parseInt(HOTEL_ID))[0] : null;
    const dispatch = useDispatch();
    const location = useLocation();
    const isLoggedIn = Session.getSessionProp("token");

    const isNonChainRefOrProjectPath = [...pathsWithoutChainOrProjectRef].includes(location.pathname);

    //queries&mutations
    const GET_PERMISSIONS = gql`
        {
            permissions (projectCode:"${
                hasCorporateUser && isCorporate ? "CORPORATE" : projectData ? projectData.code : ""
            }") 
            ${
                !isSuperUser && userID
                    ? `
            userPermissions:users (id:${userID}){ results{id roles{accesses{name}}}}`
                    : ""
            }

        ${
            hasCorporateUser && isCorporate
                ? `agregatedPermissions:permissions (projectCode:"CORPORATE" ,allChainPermissions:true)`
                : ""
        }



            ${
                isCorporate
                    ? `distinctProjectLangs{
                         results
                       }`
                    : `projectLangs ${HOTEL_ID ? `(filter: {projectID: ${HOTEL_ID}})` : ""} {
                        results {
                            isDefault
                            languageRef
                        }
                    }`
            } 
        }
    `;
    const POLICY_MUTATION = gql`
        mutation {
            updateUser(
                ref: "${Session.getSessionProp("userRef")}"
                TOSAccepted: true,
                TOSAcceptedTime: "${new window.ZDate().toString()}"
            ) {
                error
                id
                ok
            }
        }
    `;
    const PROJECT_LOGIN = gql`
        mutation {
            loginProject(
                chainRef: "${Session.getSessionProp("chainRef")}",
                projectRef: "${newProjectSelected?.ref}",
                userRef: "${Session.getSessionProp("userRef")}",
                chainId: "${Session.getSessionProp("chainId")}",
                lang:  "${i18n.language.split("-")[0]}"
            ){
                isOk
                token
            }
        }
    `;
    const GET_LOGIN_OLD_MANAGER = gql`
    {
        loginOldManager(lang :"${localStorage.getItem("lang") || "en"}") {
            isEnabled
            token
        }
    }
    `;

    //useQueries&useMutations
    const [
        executeLoginOldManager,
        { data: dataLoginOldManager, loading: loadingLoginOldManager, error: errorLoginOldManager },
    ] = useLazyQuery(GET_LOGIN_OLD_MANAGER);
    const [executeLogin, { data: dataLogin, loading: loadingLogin, error: errorLoding }] = useMutation(PROJECT_LOGIN);
    const [setPolicyAccepted, policyData] = useMutation(POLICY_MUTATION);
    const { data } = useQuery(GET_PERMISSIONS, {
        skip: isNonChainRefOrProjectPath || !isLoggedIn,
        notifyOnNetworkStatusChange: true,
    });

    //effects
    useEffect(() => {
        if (token && setPolicyToAccepted) {
            setPolicyAccepted();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (policyData.data && policyData.data.updateUser.ok) {
            Session.removeTOSAnswer();
        }
        // eslint-disable-next-line
    }, [policyData.data]);

    useEffect(() => {
        executeGetDhcpQuery();
        if (isSuperUser || data) {
            const hasNetworkAlert =
                data?.userPermissions?.results[0]?.roles[0]?.accesses?.filter(
                    (access) => access?.name === "networkAlerts"
                ) || [];
            Session.setSessionProp(
                "networkWarnings",
                (hasNetworkAlert.length > 0 || isSuperUser) && isDhcp?.isAllData ? true : false
            );
        }
    }, [isDhcp?.isAllData, data]);

    useEffect(() => {
        if (DhcpReady && data && data.permissions) {
            let projectsData = [...projects];
            const minimalPermissions = ["productTV", "productMOBILE", "productCAST", "productWIFI"];
            if (isCorporate || data.permissions.some((permission) => minimalPermissions.includes(permission))) {
                let initialRedirect = "";
                let userPermissions = [];
                //if superuser, not consider user permissions
                if (!userManagerRole && !isSuperUser) {
                    userPermissions = prepareUserPermissionsList(data.userPermissions.results[0].roles);
                }
                const permissionsCooked = ComposePermissionsTree(
                    data.permissions,
                    userPermissions,
                    userManagerRole,
                    isSuperUser,
                    data.agregatedPermissions ? data.agregatedPermissions : null,
                    hasCorporateUser
                );
                if (Object.entries(permissionsCooked.hotel).filter((item) => item[1]).length > 0) {
                    initialRedirect = "hotel";
                } else if (Object.entries(permissionsCooked.goOutSafe).filter((item) => item[1]).length > 0) {
                    initialRedirect = "common-zones";
                } else if (Object.entries(permissionsCooked.guests).filter((item) => item[1]).length > 0) {
                    initialRedirect = "customers";
                } else if (Object.entries(permissionsCooked.design).filter((item) => item[1]).length > 0) {
                    initialRedirect = "design";
                } else if (Object.entries(permissionsCooked.sales).filter((item) => item[1]).length > 0) {
                    initialRedirect = "sales";
                }

                //ensure valid rooth path
                routes.filter((route) => route.path === "/")[0].redirect = `/${initialRedirect}`;
                if (!Session.allPermissions()) {
                    //disable all routes that cant be accessed according to the permissions received
                    const routesCP = routes.concat([]);
                    routes.splice(0, routes.length);
                    routesCP.map((route) =>
                        isSectionEnabled(route, permissionsCooked, isCorporate) ? routes.push(route) : null
                    );

                    const enabledRoutes = cloneObject(routes);
                    routes.map((item) => {
                        //check if redirect point to an enabled section
                        //if not, redirect to the first enabled section of the same package
                        if (item.redirect && !enabledRoutes.find((route) => route.path === item.redirect)) {
                            if (item.package) {
                                item.redirect = getPackageFirstEnabled(
                                    enabledRoutes,
                                    item.package,
                                    item.path,
                                    permissionsCooked
                                );
                            }
                        }
                        return null;
                    });
                }
                projectsData.map((projectData) => {
                    if (parseInt(projectData.id) === parseInt(HOTEL_ID)) {
                        projectData.lastSelectedWithMinimalPermissions = true;
                    } else {
                        projectData.lastSelectedWithMinimalPermissions = false;
                    }
                });
                Session.setLSProp("oldManagerOpened", false);
                dispatch(setAvailablePaths(routes.map((route) => route?.path)));
                dispatch(setPermissions(permissionsCooked));
                dispatch(setAgregatedPermissions(data.agregatedPermissions));
                storeDefaultLang(data);
            } else {
                let nextProjectSelected = null;
                projectsData.map((projectData) => {
                    if (parseInt(projectData.id) === parseInt(HOTEL_ID)) {
                        projectData.hasMinimalPermissions = false;
                        projectData.lastSelectedWithMinimalPermissions = false;
                    } else if (projectData.ref !== "CORPORATE") {
                        if (projectData.lastSelectedWithMinimalPermissions) {
                            nextProjectSelected = projectData;
                        } else {
                            if (!nextProjectSelected && projectData.hasMinimalPermissions) {
                                nextProjectSelected = projectData;
                            }
                        }
                    }
                });
                setNewProjectSelected(nextProjectSelected);
                callExecuteLoginOldManager();
            }
            Session.setProjects(projectsData);
        }
        // eslint-disable-next-line
    }, [data, DhcpReady]);

    useEffect(() => {
        if (!loadingLogin && !errorLoding && dataLogin) {
            dispatch(showGlobalLoading(false));
            let _token = null;
            if (dataLogin && dataLogin.loginProject && dataLogin.loginProject.isOk) {
                _token = dataLogin.loginProject.token;
            }
            if (_token) {
                Session.setToken(_token);
                const newProject = {
                    id: newProjectSelected.id,
                    name: newProjectSelected.name,
                    location: newProjectSelected.location,
                    ref: newProjectSelected.ref,
                    keyBrand: newProjectSelected.keyBrand,
                    timeZone: newProjectSelected.timeZone,
                    oldmanagerurl: newProjectSelected.managerURL,
                    oldmanagerusers: newProjectSelected.managerUsers,
                    global: newProjectSelected.global ? true : false,
                    zmobileurl: newProjectSelected.zMobileURL,
                    code: newProjectSelected.code,
                };
                Session.setProject(newProject);
                window.location.href = "/";
            }
        } else if (errorLoding) {
            toast.error(t("cant obtain your projects"));
            dispatch(showGlobalLoading(false));
        }
    }, [dataLogin, loadingLogin, errorLoding]);

    useEffect(() => {
        if (!loadingLoginOldManager && !errorLoginOldManager && dataLoginOldManager) {
            if (dataLoginOldManager.loginOldManager?.isEnabled) {
                const oldManagerOpenedPrev = parseBoolean(Session.getLSProp("oldManagerOpened"));
                if (!oldManagerOpenedPrev) {
                    Session.setLSProp("oldManagerOpened", true);
                    Session.setLSProp(
                        "oldManagerConfig",
                        JSON.stringify({
                            url: currentHotel.oldmanagerurl,
                            users: currentHotel.oldmanagerusers,
                            tokenCloud: dataLoginOldManager.loginOldManager.token,
                        })
                    );
                    window.open("/ZAFIRO_TV", "_blank").focus();
                }
            }
            updateProject({ newProjectSelected });
        } else if (errorLoginOldManager) {
            toast.error(t("error-mutation"));
            dispatch(showGlobalLoading(false));
            updateProject({ newProjectSelected });
        }
    }, [dataLoginOldManager, loadingLoginOldManager, errorLoginOldManager]);

    //arrangeData

    //handle&Functions
    if (!token || (isSuperUser && !projectData)) {
        // REVIEW what is this?
        dispatch(setPermissions([{ name: "login", value: true }]));
    }

    const storeDefaultLang = (data) => {
        let langs = [];
        if (data.projectLangs) {
            langs = data.projectLangs.results;
            langs.map((lang) => (lang.isDefault ? Session.setSessionProp("managerDefLang", lang.languageRef) : null));
        } else if (data.distinctProjectLangs) {
            data.distinctProjectLangs.results.map((lang, index) =>
                langs.push({ isDefault: index === 0, languageRef: lang })
            );
        }
        dispatch(setProjectLangs(langs));
    };

    const prepareUserPermissionsList = (userRoles) => {
        let perArray = [];
        userRoles.map((role) =>
            role.accesses.map((accesse) => (!perArray.includes(accesse.name) ? perArray.push(accesse.name) : null))
        );

        return perArray;
    };

    const getPackageFirstEnabled = (enabledRoutes, packageName, path, permissions) => {
        let returnItem = null;
        enabledRoutes.map((route) => {
            if (
                !returnItem &&
                route.package === packageName &&
                isSectionEnabled(route, permissions) &&
                route.path !== path
            ) {
                returnItem = route.redirect ? route.redirect : route.path;
            }
            return null;
        });
        return returnItem;
    };

    const isSectionEnabled = (route, permissions, isCorporate = false) => {
        let enabled = Session.allPermissions() || !route.package;
        if (route.package) {
            if (permissions[route.package]) {
                if (route.attribute) {
                    // Check if package.attribute is true
                    enabled = permissions[route.package][route.attribute];
                } else {
                    // Check if at least 1 attribute in package is true
                    let packageEnabled = false;
                    Object.keys(permissions[route.package]).forEach((item) => {
                        if (permissions[route.package][item]) {
                            packageEnabled = true;
                        }
                    });
                    enabled = packageEnabled;
                }
                if (enabled && route.check) {
                    enabled = route.check({ permissions, isCorporate, userManagerRole, isDhcp, isSuperUser });
                }
            } else {
                enabled = false;
            }
        }
        return enabled;
    };

    const updateProject = (props = {}) => {
        const { newProjectSelected = null } = props;
        if (newProjectSelected) {
            executeLogin();
        } else {
            Session.close();
            window.location.href = "/";
        }
    };

    const callExecuteLoginOldManager = () => {
        dispatch(showGlobalLoading(true));
        executeLoginOldManager();
    };

    //renders

    //Response
    return <></>;
};

export default Permissions;
